////////////////////////////////////
////////////////////////////////////
/////////ARRAYS////////////
////////////////////////////////////
////////////////////////////////////

/**
 * Para borrar un elemento de un array, se le pasa el indice a borrar
 * @param indice
 * @returns {Array.<*>}
 */
Array.prototype.remove = function (indice) {
    return this.splice(this.indexOf(indice), 1);
};

/**
 * Busca una clave y valor que coincidan dentro de un array de json y devuelve el json encontrado, sino devuelve null
 * @param array
 * @param key
 * @param value
 * @returns {null}
 */
Array.prototype.findInArrayJson = function (key, value) {
    var jsonEncontrado = null;
    $.each(this, function (index, json) {
        if (json[key] == value) {
            jsonEncontrado = json;
        }
    });
    return jsonEncontrado;
};

/**
 * Clona un array de json
 * @returns {Array}
 */
Array.prototype.cloneArrayJson = function () {
    var aux = [];
    $.each(this, function (index, jsonOriginal) {
        aux[index] = $.extend({}, jsonOriginal);
    });
    return aux;
};

////////////////////////////////////
////////////////////////////////////
/////////STRINGS////////////
////////////////////////////////////
////////////////////////////////////
/**
 * Formatea un string
 * @param args args array con los argumentos de cambio
 * @returns {String} string formateado
 */
String.prototype.format = function (args) {
    var str = this;
    for (var i = 0; i < args.length; i++) {
        str = str.split('{' + i + '}').join(args[i].toString());
    }
    return str;
};

/**
 * Corta un texto por un tamaño dado, se pueden agregar puntos al final y cortar o no las palabras del texto
 * @param tamano el tamaño del texto
 * @param puntos true si al final se agregan 3 puntos
 * @param cortarPalabras true si se pueden cortar palabras
 * @returns string cortado
 */
String.prototype.cortar = function (tamano, puntos, cortarPalabras) {
    var texto = this;
    if (tamano < texto.length) {
        //si queremos incorporar puntos el tamaño se reduce en tres
        //el tamaño ha de ser mayor que dos caracteres
        if (puntos && tamano > 2) {
            tamano -= 3;
        }
        //si podemos cortar las palabras o no
        if (cortarPalabras) {
            texto = texto.substring(0, tamano);
        } else {
            // Cortamos la cadena por los espacios
            var contador = 0;
            var arrayTexto = texto.split(' ');
            texto = '';
            // Reconstruimos la cadena
            while (tamano >= (texto.length + arrayTexto[contador].length + 1)) {
                texto += ' ' + arrayTexto[contador];
                contador++;
            }
            texto = texto.trim();
        }
        if (puntos) {
            return texto += cortarPalabras ? '...' : ' ...';
        }
    }
    return texto;
};

/**
 * Rellena con los elementos indicados el inicio de un string
 * @param targetLength
 * @param padString
 * @returns {string}
 */
String.prototype.padStart = function padStart(targetLength, padString) {
    targetLength = targetLength >> 0; //floor if number or convert non-number to 0;
    padString = String(padString || ' ');
    if (this.length > targetLength) {
        return String(this);
    }
    else {
        targetLength = targetLength - this.length;
        if (targetLength > padString.length) {
            padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
        }
        return padString.slice(0, targetLength) + String(this);
    }
};

////////////////////////////////////
////////////////////////////////////
/////////DATES////////////
////////////////////////////////////
////////////////////////////////////

/**
 * Convierte los segundos en un string de tiempo
 * @param seg segundos
 * @returns {string} '4:30:20' 4 horas 30 minutos y 20 segundos
 */
Date.prototype.convertirSegundosToTimeString = function (seg) {
    var horas = Math.floor(seg / 3600);
    var minutos = Math.floor((seg - (horas * 3600)) / 60);
    var segundos = seg - (horas * 3600) - (minutos * 60);
    if (horas < 10) {
        horas = '0' + horas;
    }
    if (minutos < 10) {
        minutos = '0' + minutos;
    }
    if (segundos < 10) {
        segundos = '0' + segundos;
    }
    return horas + ':' + minutos + ':' + segundos;
};
/**
 * Da formato a una fecha
 * @param format (dd dia,mm mes,yyyy year, MM nombre del mes,DD nombre del dia, DDD nombre del dia corto, MMM nombre del mes corto)
 */
Date.prototype.format = function (format) {
    var monthNames = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ];
    var dayNames = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado'
    ];
    var monthNamesCorto = [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic'
    ];
    var dayNamesCorto = [
        'Dom',
        'Lun',
        'Mar',
        'Mie',
        'Jue',
        'Vie',
        'Sáb'
    ];
    var dia = String(this.getDate()).padStart(2, '0');
    var mes = String((this.getMonth() + 1)).padStart(2, '0');
    var year = this.getFullYear();
    var diaNombre = dayNames[this.getDay()];
    var diaNombreCorto = dayNamesCorto[this.getDay()];
    var mesNombre = monthNames[this.getMonth()];
    var mesNombreCorto = monthNamesCorto[this.getMonth()];

    var aux = format.replace('dd', dia);
    aux = aux.replace('mm', mes);
    aux = aux.replace('yyyy', year);
    aux = aux.replace('DDD', diaNombreCorto);
    aux = aux.replace('MMM', mesNombreCorto);
    aux = aux.replace('DD', diaNombre);
    aux = aux.replace('MM', mesNombre);

    return aux;
};

Date.prototype.formatoFechaFormatoLargo = function () {
    return this.format('DD, dd de MM de yyyy');
};

Date.prototype.parseDate = function (input, format) {
    format = format || 'yyyy-mm-dd'; // default format
    var parts = input.match(/(\d+)/g),
        i = 0, fmt = {};
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function (part) {
        fmt[part] = i++;
    });

    return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']]);
};

Date.prototype.addDays = function (days) {
    var dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
};

Date.prototype.getLastDayOfMonth = function (year, month) {
    var d = new Date(year, month, 0);
    return d.getDate();
};

Date.prototype.getStringDate = function () {
    return this.format('yyyy-mm-dd');
};

Date.prototype.formatoFechaCorto = function () {
    return this.format('dd/mm/yyyy');
};

////////////////////////////////////
////////////////////////////////////
/////////NUMBERS////////////////////
////////////////////////////////////
////////////////////////////////////

Number.prototype.roundDown = function (decimals) {
    var number = this;
    decimals = decimals || 0;
    return (Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals));
};

/* jquery */

//reemplaza las opciones de un select
(function ($, window) {

    /**
     * Reemplaza las opciones de un select
     * @param options options = [{text: "one", value: 1,data:{}},{text: "two", value: 2,data:{}}];
     * @param key
     * @param value
     */
    $.fn.replaceOptions = function (options, key, value) {
        var nameKey = key ? key : 'key';
        var nameValue = value ? value : 'value';

        var self, $option;

        this.empty();
        self = this;

        $.each(options, function (index, option) {
            $option = $('<option></option>').attr('value', option[nameKey]).text(option[nameValue]);
            //si tenemos datas los ponemos en la etiqueta
            if (option.data) {
                $.each(option.data, function (key, value) {
                    $option.data(key, value);
                });
            }
            self.append($option);
        });
        self.selectpicker('refresh');
    };
})(jQuery, window);