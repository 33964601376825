/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./prototypes');
//require('./theme/modernizr');

MyApp = {};

MyApp.Common = {
    makeEditor: function (selector, options) {
        options = $.extend(true, {
            fontSizes: [
                '10',
                '14'
            ],
            toolbar: [
                // [groupName, [list of button]]
                [
                    'style',
                    [
                        'bold',
                        'italic',
                        'underline',
                        'clear'
                    ]
                ],
                /*
                [
                    'font',
                    ['strikethrough']
                ],
                */
                /*
                [
                    'fontsize',
                    ['fontsize']
                ],
                */
                /*
                [
                    'para',
                    [
                        'ul',
                        'ol',
                        'paragraph'
                    ]
                ]
                */
            ],
            placeholder: '',
            tabsize: 2,
            height: 200
        }, options);

        $(selector).summernote(options);
    },
    formValidate: function (form, options) {
        options = $.extend(true, {
            errorElement: 'span', //default input error message container
            errorClass: 'help-block', // default input error message class
            focusInvalid: true, // do not focus the last invalid input
            ignore: ':hidden:not(#summernote),div.note-editable.card-block', //for summernote

            /* invalidHandler : function (event, validator) {
             },

             highlight : function (element) { // hightlight error inputs
                 $(element).closest('.form-group').addClass('has-error'); // set error class to the control group
             },

             success : function (label) {
                 label.closest('.form-group').removeClass('has-error');
                 label.remove();
             },*/

            errorPlacement: function (error, element) {
                var selector = element.closest('.input-group');
                if (selector.length)
                    error.insertAfter(selector);
                else
                    error.appendTo(element.closest('.form-group'));

            }
        }, options);
        $(form).validate(options);
    },
    imagePreview: function (input, imageResult) {
        var files = $(input).prop('files');
        if (files && files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                $(imageResult).attr('src', e.target.result);
            };

            reader.readAsDataURL(files[0]);
        }
    },
    modalConfirm: function (options) {
        options = $.extend(true, {
            title: Constants.Translates.message_confirm_title,
            message: Constants.Translates.message_confirm_text,
            textOk: Constants.Translates.confirm,
            textCancel: Constants.Translates.cancel,
            Ok: function () {
            },
            Cancel: function () {
            }
        }, options);

        var modalConfirm = $('#modal-confirm').clone();

        $('#modal-confirm-title', modalConfirm).html(options.title);
        $('#modal-confirm-message', modalConfirm).html(options.message);
        $('#modal-confirm-ok', modalConfirm).html(options.textOk);
        $('#modal-confirm-cancel', modalConfirm).html(options.textCancel);

        $('#modal-confirm-ok', modalConfirm).click(function () {
            console.log('si');
            modalConfirm.modal('hide');
            options.Ok();
        });
        $('#modal-confirm-cancel', modalConfirm).click(function () {
            console.log('no');
            modalConfirm.modal('hide');
            options.Cancel();
        });
        modalConfirm.modal();
    },
    sendForm: function (form, options) {
        //disabled button submit
        $(form).find('[type="submit"]').prop('disabled', true);
        options = $.extend(true, {
            url: $(form).prop('action'),
            data: $(form).serialize(),
            complete: function () {
                //enabled button submit
                $(form).find('[type="submit"]').prop('disabled', false);
            }
        }, options);
        MyApp.ajax.sendPost(options);
    },
    loading: function () {
        $('#plugin-loading').find('#loading-circle').show();
        $('#plugin-loading').show();
    },
    loadingOff: function () {
        $('#plugin-loading').hide();
    },

};

MyApp.alert = {
    error: function (message) {
        message = message ? message : Constants.Translates.message_error_msg;
        this.launch(message, 'alert-danger');
    },
    ok: function (message) {
        message = message ? message : Constants.Translates.message_ok_msg;
        this.launch(message, 'alert-success');

    },
    launch: function (message, style) {
        var alert = $('#container-alerts').find('#alert').clone();
        alert.find('#alert-text').html(message);
        alert.addClass(style);
        $('#container-alerts').append(alert);
        alert.show();
        setTimeout(function () {
            alert.remove();
        }, 8000);
    }
};

MyApp.message = {
    error: function (title, message) {
        title = title ? title : Constants.Translates.message_error_title;
        $('#error-modal .modal-title').html(title);
        $('#error-modal .modal-body p').html(message);
        $('#error-modal').modal();
    },
    ok: function (title, message) {
        title = title ? title : Constants.Translates.message_ok_title;
        $('#ok-modal .modal-title').html(title);
        $('#ok-modal .modal-body p').html(message);
        $('#ok-modal').modal();
    },
    warning: function (title, message) {
        title = title ? title : Constants.Translates.message_ok_title;
        $('#ok-modal .modal-title').html(title);
        $('#ok-modal .modal-body p').html(message);
        $('#ok-modal').modal();
    }
};

MyApp.ajax = {
    sendGet: function (options) {
        MyApp.Common.loading();
        options = $.extend(true, {
            type: 'GET'
        }, options);
        $.ajax(options);
    },
    sendPost: function (options) {
        MyApp.Common.loading();
        options = $.extend(true, {
            type: 'POST'
        }, options);
        $.ajax(options);
    }
};

MyApp.maps = {};

MyApp.functions = {
    insertParam: function (key, value) {
        key = encodeURI(key); value = encodeURI(value);

        var kvp = document.location.search.substr(1).split('&');

        var i = kvp.length; var x; while (i--) {
            x = kvp[i].split('=');

            if (x[0] == key) {
                x[1] = value;
                kvp[i] = x.join('=');
                break;
            }
        }

        if (i < 0) { kvp[kvp.length] = [key, value].join('='); }

        //this will reload the page, it's likely better to store this until finished
        document.location.search = kvp.join('&');
    }
}

// Ajax token
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    beforeSend: function () {

    },
    complete: function () {
        MyApp.Common.loadingOff();
    },
    error: function (jqXHR) {
        var data = jqXHR.responseJSON ? jqXHR.responseJSON : JSON.parse(jqXHR.responseText);
        var title = data.title ? data.title : Constants.Translates.message_error_title;
        var message = data.msg ? data.msg : Constants.Translates.message_error_msg;
        MyApp.message.error(title, message);
    }
});

// Ajax modal
$(document).on('click', '[data-toggle="modal"]', function () {
    MyApp.Common.loading();
    var element = $(this);
    setTimeout(function () {
        $.ajax({
            type: 'GET',
            url: element.data('url'),
            data: {},
            success: function (response) {
                $('#page-modal').html(response);
                $('#page-modal').modal();
            }
        });
    }, 300);
});

// Post modal
$(document).on('click', '[data-toggle="modal-post"]', function () {
    MyApp.Common.loading();
    var element = $(this);
    setTimeout(function () {
        $.ajax({
            type: 'POST',
            url: element.data('url'),
            data: element.data(),
            success: function (response) {
                $('#page-modal').html(response);
                $('#page-modal').modal();
            }
        }, 300);
    });
});

/** events listener **/

$(document).on('click', '[data-action="goToUrl"]', function () {
    location.href = $(this).data('url');
});